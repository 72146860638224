<template>
  <v-app style="background-color: #f2f2f2">
    <v-layout row wrap align-center justify-center>
      <v-flex lg4 md5 sm7 xs7 class="text-center">
        <img src="@/assets/logo_onebox-horizontal-png.png" width="45%" height="45%" alt="OneBox" />
        <br />
        <br />
        <v-progress-linear
          v-if="fail_ === false"
          background-color="light-blue lighten-4"
          color="success"
          indeterminate
          rounded
        ></v-progress-linear>
        <br />
        <p v-if="fail_ === false" class="text-center">รอสักครู่</p>
        <p v-else class="text-center"><v-icon color="error">error</v-icon>&nbsp;{{errormessage}}</p>
      </v-flex>
    </v-layout>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import VueCookies from "vue-cookies";
import CryptoJS from "crypto-js";

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  data: function() {
    return {
      fail_: false,
      errormessage: "",
      statusevaluation:"",
      eva_expired:""
    };
  },
  created() {},
  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "role_level"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataLoginDefault",
    ]),
  },
  methods: {
  goto_myfile(){
      this.$router.push({path:'/mydrive'});
  },
  // reset_login() {
  //     this.$store
  //       .dispatch("authorize_expired")
  //       .then(this.get_profile())
  //       .catch((error) => {
  //         console.log(error);
  //       });
  // },
  // ยิง api getprofile เพื่อ get ค่าข้อมูล
  // async get_profile() {
  //   console.log("accesstoken",this.$route.query.accesstoken);


  //   this.loadprogress = true;
  //   let payload = {
  //     accesstoken: this.$route.query.accesstoken,
  //   };
  //   console.log("payload", payload);
  //   this.axios
  //     .post(
  //       process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
  //       "/api/getprofile",
  //       payload,

  //     )
  //     .then((response) => {
  //       console.log("res",response);
  //       if (response.data.status === "OK") {
         
  //         // เอาข้อมูล ใน api getprofile มาใส่ใน payload เพื่อส่งค่า payload ไปยัง goto_biz เพื่อไปยิง login_business
  //         // let payload = {
  //         //     taxid: result.data.result.login_default.id_card_num,
  //         //     accesstoken: result.data.result.accesstoken,
  //         //     biz_id: result.data.result.login_default.id,
  //         //     branch_no: result.data.result.login_default.branch_no || "00000",
  //         // };
  //         // console.log("payload", payload);
  //         // Toast.fire({
  //         //     icon: "success",
  //         //     title: this.$t("settinfdefaulesuccess"),
  //         // });
  //         this.loadprogress = false;
  //          //   this.goto_biz(payload);
  //       } else {
  //         Toast.fire({
  //           icon: "error",
  //           title: response.data.errorMessage,
  //         });
  //       }
  //     });
    
  // },
  async get_profile_new(){
    console.log("this.$route.query.accesstoken",this.$route.query.accesstoken);
    let acess = this.$route.query.accesstoken.split("?")
    console.log("acess",acess);
  let payload = {
    accesstoken: acess[0]
      // accesstoken: this.dataAccesstoken
    }
    await this.axios
    .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/getprofile", payload)
    .then((response) => {
      return new Promise((resolve, reject) => {
        console.log("response",response);          
        if (response.data.status === "OK") {
          if(this.statusevaluation === 'Y' && this.eva_expired === 'N'){
          this.$emit('closedialog');
          this.$emit('reload');
          }else{
            this.fn_no_evaluate();
          }
          this.goto_biz();
        }
      });
    })
    .catch((err) => {})
  },
    
  async fn_no_evaluate(){
    let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))
    let acess = this.$route.query.accesstoken.split("?")
    console.log("acess",acess);
    let status_evaluate = ''
      if(acess[1] === "status=success"){
        status_evaluate = "Y"
      }else{
          status_evaluate = "W"
      }

    let payload = {
      one_id: one_id,
      status_evaluate: status_evaluate
    };
    console.log("payload", payload);
    await this.axios
      .post(
        process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
        "/api/insert_user_evaluate",
        payload,

      )
      .then((response) => {
        return new Promise((resolve, reject) => {
        console.log("res",response);
        if (response.data.status === "OK") {
          if(status_evaluate === "Y"){
            VueCookies.set("iconeva",false)
          }else{
              VueCookies.set("iconeva",true)
          }
           this.goto_myfile();
          this.$emit('closedialog');
          // // window.location.reload(true);
          this.$emit('reload');
          
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }

      });
      })
      .catch((err) => {})

  },
  // ส่งค่า payload เพื่อไปยิง api gotobiz_business เพื่อไปหน้าไดร์ฟบริษัทที่เลือก
  async goto_biz(payload) {
    // กรณีเลือก ผู้ใช้งานทั่วไป
    // if (this.id_biz === "") {
      // console.log("gotobiz_citizen", this.id_biz);
      this.$store.dispatch("switchaccount_citizen")
      this.$router.push("/mydrive");
      // this.$emit("closedialog");
      // this.Business_default = '';
      // this.$emit('reload')
    // } 
    // กรณีเลือก บริษัท
    // else {
    //   console.log("gotobiz_business", this.id_biz);
    //  await this.$store.dispatch("switchaccount_business", payload).then((msg) => {
    //     console.log("msg", msg.status);
    //     if (msg.status === "Business Access Granted") {
    //       console.log("ok");
    //       this.$router.push({
    //         path:
    //           "/directory/" +
    //           this.dataAccountActive.business_info.my_folder_id ,
    //       });
    //       // this.$router.replace({
    //       //   name: "directory",
    //       //   params: { id: this.dataAccountActive.business_info.my_folder_id },
    //       // });
    //     }
    //   });
    //   this.$emit("closedialog");
    //   this.$emit("reload");
    //   // this.Business_default = '';
    // }
  },
  fn_checkevaluate(){
      let one_id = CryptoJS.AES.decrypt(VueCookies.get("accountid"), CryptoJS.enc.Utf8.parse(process.env.VUE_APP_SECRET_KEY), { mode: CryptoJS.mode.ECB, });
      one_id = window.atob(one_id.toString(CryptoJS.enc.Utf8))

      let payload = {
        one_id: one_id
      };
      console.log("payload", payload);
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_AUTHORIZE_API + 
          "/api/get_user_evaluate",
          payload,
        )
        .then((response) => {
          console.log("response",response);
          if (response.data.status === "OK") {
            this.statusevaluation = response.data.data.status_evaluate
            this.eva_expired = response.data.data.evaluate_expired

          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
  }

  },
  mounted() {
    console.log(",kkkkkkkkk", this.$route.query);
    // this.goto_myfile();
    this.get_profile_new();
    this.fn_checkevaluate();
  //   this.get_profile();
  //   this.reset_login();
  }
}
</script>
